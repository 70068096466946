/**
 * @name: 商品管理-商品分类管理接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 商品管理-商品分类管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPage, } from "@/apis/page";
import {IProductSortCreat} from "@/apis/product/sort/types";

/**
 *分页查询
 */
export const productSortQueryApi = (param:IPage) => get("/admin/productSort/query",param)

/**
 *创建
 */
export const productSortCreateApi = (data:IProductSortCreat) => postJ("/admin/productSort/create",data)

/**
 * 修改商品分类状态
 */
export const productSetStatusApi = (id:string) => get("/admin/productSort/setStatus/"+id)

/**
 * 详情
 */
export const productSetDetailApi = (id:string) => get("/admin/productSort/detail/"+id)

/**
 * 删除
 * @param id
 * @returns
 */
export const  productSortRemoveApi = (id: string) => get("/admin/productSort/delete/" + id)
/**
 *修改
 */
export const productSortModifyApi = (data:IProductSortCreat) => postJ("/admin/productSort/modify",data)

/**
 * 分类下拉
 * @returns
 */
export const productSortListApi = () => get("/admin/productSort/dropDown")
